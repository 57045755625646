/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2020-12-31 16:39:18
 * @LastEditors: hutian
 * @LastEditTime: 2021-01-07 12:05:00
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listProductTag = params => axios({
    url: '/api/product/system/tag/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addProductTag = params => axios({
    url: '/api/product/system/tag/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editProductTag = params => axios({
    url: '/api/product/system/tag/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delProductTag = params => axios({
    url:'/api/product/system/tag/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdProductTag = params => axios({
    url: '/api/product/system/productTag/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
